.footer {
  background-color: $black;

  &__row {
    justify-content: space-between;
  }

  &__heading {
    font-size: 1.5rem;
    text-transform: uppercase;
    margin-bottom: 1em;
  }

  &__link {
    color: $white;
    position: relative;
    &::after{
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 1px;
        background-color: $white;
        opacity: 0;
        transform: translateY(3px);
        transform-origin: top;
        transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
    }
    &:hover{
        color: $white;
        
        &::after{
            opacity: 1;
            transform: translateY(0);
            transform-origin: bottom;
            transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
        }
    }
  }

  &__social {
    padding: 1em 0;
    justify-content: space-between;
    width: 95px;
  }

  &__list {
    padding-left: 0;
    list-style: square inside url("../img/icons/rectangle.png");
    &-item {
    }
  }

  &__col {
    @media only screen and (max-width: 576px) {
      padding: 1em;
      border-bottom: 1px solid $gray-900;
    }
    &--quickLink {
      text-transform: uppercase;
    }

    &--stockedBrands {
      ul {
        list-style: none;
      }
    }

    &--findUs {
      text-transform: uppercase;
    }
  }
}

.not-found {

    &__content {
        padding-top: calc(107px + 40px);
        padding-bottom: 50px;

        @media (min-width: 576px) {
            padding-top: calc(107px + 60px);
            padding-bottom: 60px;
        }

        @media (min-width: 768px) {
            padding-top: calc(107px + 80px);
            padding-bottom: 80px;
        }

        @media (min-width: 992px) {
            padding-top: calc(107px + 90px);
            padding-bottom: 90px;
        }

        @media (min-width: 1200px) {
            padding-top: calc(107px + 100px);
            padding-bottom: 100px;
        }
    }

    &__error {
        font-size: 80px;
        margin: 0;

        @media (min-width: 576px) {
            font-size: 130px;
        }

        @media (min-width: 768px) {
            font-size: 150px;
        }

        @media (min-width: 1200px) {
            font-size: 160px;
        }
    }

    &__text {
        font-weight: 450;
        font-size: 16px;
        line-height: 22px;
        // color: $grey;
        margin-top: 0;
        margin-bottom: 5px;
    }

    // &__links {
    //     @extend .list;
    // }
    
    // &__item {

    // }

    &__link {
        text-decoration: none;
        // color: $orange;
    }
}
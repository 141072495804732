$background--color: #1e2023;
$icon--color: #1e2023;
$font--color: #ffffff;
$font--color--active: #ffffff;
$transition--length: 0.7;

//default state
.menu-icon {
  $size: 30px;
  height: $size;
  width: $size;
  cursor: pointer;
  padding: 5px 0;
  &__line {
    height: 2px;
    width: $size;
    display: block;
    background-color: $font--color;
    margin-bottom: 4px;
    transition: transform 0.2s ease, background-color 0.5s ease;
  }
  &__line-left {
    width: $size / 2;
  }
  &__line-right {
    width: $size / 2;
    float: right;
  }
}

.mainNav {
  $width: 100vw;
  $height: 100vh;
  $font--size--calc: calc(2vw + 10px);
  $transition--easing: cubic-bezier(0.77, 0, 0.175, 1);
  position: fixed;
  z-index: 99;
  &:before,
  &:after {
    content: "";
    position: fixed;
    width: $width;
    height: $height;
    background: rgba($dark, 0.2);
    z-index: -1;
    transition: transform $transition--easing $transition--length + s;
    transform: translateX(0%) translateY(-100%);
  }
  &:after {
    background: rgba($black, 1);
    transition-delay: 0s;
  }
  &:before {
    transition-delay: 0.1s;
  }
  &__content {
    position: fixed;
    top: 50%;
    transform: translate(0%, -50%);
    width: 100%;
    text-align: center;
    font-size: $font--size--calc;
    font-weight: 200;
    pointer-events: none;
    cursor: pointer;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: none;
    }
  }
  &__list-item {
    display: block;
    transition-delay: $transition--length + s;
    opacity: 0;
    transform: translate(0%, 100%);
    transition: opacity 0.2s ease, transform 0.3s ease;
    margin-right: 25px;
    @media only screen and (max-width: 576px) {
      padding: 0.3em 0;
    }
  }

  &__list-link {
    position: relative;
    text-transform: uppercase;
    font-weight: 200;
    color: $white;

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 1px;
      background: $font--color--active;
      transform: scaleX(0);
      transform-origin: right;
      transition: transform 500ms ease-in-out;
    }
    &:hover {
      color: $font--color--active;
      &:before {
        width: 100%;
        transform: scaleX(1);
        transform-origin: left;
        transition: transform 500ms ease-in-out;
      }
    }
  }

  &__social {
    display: flex;
    padding: 2em;
  }

  .socialNav {
    position: fixed;
    top: 50%;
    right: 0;
    min-height: 158px;
    flex-direction: column;
    justify-content: space-between;
    opacity: 0;
    pointer-events: none;
    transform: translateY(-50%);
    transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
    @media only screen and (max-width: 1200px) {
      top: unset;
      right: unset;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      flex-direction: row;
      min-height: auto;
      min-width: 158px;
    }
  }

  &__socialLink {
    color: $dark;
    background-color: $white;
    width: 40px;
    height: 40px;
    text-align: center;
    border-radius: 50%;
    font-size: 1.3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
    transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
    &:hover {
      color: $white;
      transition: background-color 200ms ease-in-out, color 200ms ease-in-out;
    }

    &--instagram {
      position: relative;
      overflow: hidden;
      background-color: transparent;
      i{z-index: 4;}
      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: opacity 200ms ease-in-out;
      }
      &::before {
        background: #d6249f;
        background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285aeb 90%);
      }
      &::after {
        background: $white;
      }
    }

    &--instagram:hover::after {
      opacity: 0;
      transition: opacity 200ms ease-in-out;
    }
    &--facebook:hover {
      background-color: #3b5998;
    }
    &--twitter:hover {
      background-color: #00acee;
    }
    &--youtube:hover {
      background-color: #c4302b;
    }
  }
}

//active state
body.nav-active {
  .header {
    background-color: $black;
    transition: background-color 500ms ease-in-out;
    transition-delay: 200ms;
    color: $white;
    transform: none;
  }

  $menu--items--count: 7;
  .menu-icon {
    &__line {
      background-color: $white;
      transform: translateX(0px) rotate(-45deg);
    }
    &__line-left {
      transform: translateX(1px) rotate(45deg);
    }
    &__line-right {
      transform: translateX(-2px) rotate(45deg);
    }
  }
  .mainNav {
    visibility: visible;
    &:before,
    &:after {
      transform: translateX(0%) translateY(0%);
    }
    &:after {
      transition-delay: 0.1s;
    }
    &:before {
      transition-delay: 0s;
    }
    &__list-item {
      opacity: 1;
      transform: translateX(0%);
      transition: opacity 0.3s ease, transform 0.3s ease, color 0.3s ease;
      @for $i from 0 through $menu--items--count {
        &:nth-child(#{$i}) {
          transition-delay: $transition--length * $i / 8 + 0.5 + s;
        }
      }
    }

    &__content {
      pointer-events: visible;
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
      }
    }

    .socialNav {
      opacity: 1;
      transform: translateY(-60%);
      pointer-events: painted;
      transition: opacity 200ms ease-in-out, transform 200ms ease-in-out;
      transition-delay: 400ms;

      @media only screen and (max-width: 1200px) {
        transform: translateX(-50%);
      }
    }
  }
}

.page-template-page-made-to-measure {
  .banner {
    h1 {
      font-size: 48px;
      // font-weight: 200;
      line-height: 58px;
      text-transform: uppercase;
      text-align: center;
      letter-spacing: 10px;
      @media only screen and (max-width: 576px) {
        font-size: 30px;
        letter-spacing: 5px;
        line-height: 35px;
      }
    }
  }

  .texImg{
      h2{
          font-size: 2rem;
      }

      &__col{
          &--img{
              background-size: contain;
              min-height: 400px;
          }
      }
  }
}
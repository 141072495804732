.header {
  padding: 0 2em;
  width: 100%;
  height: 100px;
  position: fixed;
  top: 0;
  z-index: 999;
  transition: background-color 500ms ease-in-out;

  display: flex;
  justify-content: space-between;
  align-items: center;

  @media only screen and (max-width: 600px) {
    height: 60px;
  }

  .phone {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $white;
    transition: color 250ms ease-in-out;
    i {
      font-size: 25px;
      margin-right: 0.3em;
    }
    span {
      font-weight: 500;
      @media only screen and (max-width: 768px) {
        display: none;
      }
    }

    &:hover{
      color: darken($white, 30%);
    }
  }

  .logo {
    width: 195px;
    height: 90px;
    margin-right: 80px;
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    @media only screen and (max-width: 576px) {
    margin-right: 0;
    width: 100px;
    }
  }

  &--dark {
    background-color: transparent;
    color: $white;
    .logo {
      opacity: 0;
      transform: translateY(-50%);
      transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
      background-image: url(../../img/nav/white-logo.svg);
    }
    .menu-icon__line {
      background-color: $white;
    }
  }
  &--light {
    background-color: $white;
    color: $dark;
    .phone {
      color: $black;
    }
    .menu-icon__line {
      background-color: $dark;
    }

    .logo {
      background-image: url(../../img/nav/black-logo.svg);
    }
  }

  &--hide {
    transform: translateY(-100px);
    transform-origin: top;
    transition: transform 500ms ease-in-out;
  }
  &--show {
    transform: translateY(0);
    transform-origin: top;
    transition: transform 500ms ease-in-out;
    background-color: $white;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 2px 5px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    .logo{
        opacity: 1;
        transform: none;
        background-image: url(../../img/nav/black-logo.svg);
    }
  }
}

body.nav-active {
  .header {
    .phone {
      color: $white;
    }

    .logo {
      background-image: url(../../img/nav/white-logo.svg);
      opacity: 1;
      transform: translateY(0);
      transition: transform 400ms ease-in-out, opacity 400ms ease-in-out;
      transition-delay: 400ms;
    }
  }
}
.tax-product_category, .single{
    background-color: $light;
}

.banner {
  height: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $white;
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  @media only screen and (max-width: 768px) {
      width: 100%;
      height: 500px;
  }

  &::before{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba($black, 0.5);
  }

  &__content {
    width: 650px;
    padding: 1em;
    z-index: 2;
    h1 {
      font-size: calc(2.3vw + 20px);
      letter-spacing: 10px;
      @media only screen and (max-width: 576px) {
          letter-spacing: 5px;
      }
      strong{
          display: block;
          font-weight: 900;
      }
    }
  }
}

/* ================================================== */
/* ================================================== */

.product{
    text-align: center;
    
    &__col{
        position: relative;
    }
    
    &__content{
        position: relative;
        padding-bottom: 40px;
    }

    &__img{
        display: block;
        width: 100%;
        height: 400px;
        background-color: white;
        background-size: contain;
        background-position: center center;
        background-repeat: no-repeat;
        transition: opacity 250ms ease-in-out;

        &--zoomed{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            opacity: 0;
        }
    }

    &__content:hover{
        .product__img{
            &--main{
                opacity: 0;
            }
            &--zoomed{
                opacity: 1;
            }
        }
    }

    &__title{
        text-transform: uppercase;
    }

    &__btnWrap{
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        // display: flex;
        // justify-content: space-evenly;
        // align-items: center;
    }

    &__buyorhire{
        display: inline-block;
        border: 2px solid $black;
        padding: 0.4em 1em 0.3em 1em;
        text-transform: uppercase;
        letter-spacing: 4px;
        font-weight: 600;

        &--hire{
            background-color: $black;
            color: $white;
        }
    }
}
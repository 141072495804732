html {
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: "Fira Sans", sans-serif;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  color: $black;

  // &.nav-active {
  //   overflow: hidden;
  //   height: 100%;
  // }
}

footer {
  margin-top: auto;
}

/* BUTTON */
.btn {
  position: relative;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2.5px;
  padding: 0.5rem 0.8rem;
  cursor: pointer;

  color: $white;
  transition: all 0.5s;
  background-color: transparent;
  border: 0;
  border-radius: 0;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border: 2px solid white;
    transition: all 0.3s;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    background-color: rgba(255, 255, 255, 0.25);
    transition: all 0.3s;
    transform: scale(0.5, 0.5);
  }

  &:hover {
    background-color: transparent;
    &:before {
      opacity: 0;
      transform: scale(1.2, 1.2);
    }

    &:after {
      opacity: 1;
      transform: scale(1, 1);
    }
  }

  &-secondary {
    &:before {
      opacity: 1;
      transform: scale(1, 1);
      background-color: $black;
      z-index: -1;
      border: none;
    }

    &:after {
      opacity: 0;
      transform: scale(1.2, 1.2);
      background-color: transparent;
      border: 2px solid $white;
    }

    &:hover {
      &:before {
        opacity: 0;
        transform: scale(0, 0);
      }
    }
  }

  &-dark {
    color: $black;
    &::before {
      border-color: lighten($black, 15%);
    }
    &::after {
      background-color: lighten($black, 15%);
      z-index: -1;
    }

    &:hover {
      color: $white;
      &::after {
        background-color: lighten($black, 15%);
      }
    }
  }
}
.btnWrap {
  z-index: 1;
}
/* LINK */
a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}

/* Basic Page */

.courseInfo {
  margin-top: 100px;
  @media only screen and (max-width: 576px) {
    margin-top: 50px;
  }
}

.theContent {
  background-color: $white;
  padding: 2em;
  margin-top: 2em;
  box-shadow: 0 0 10px rgba($dark, 0.3);
}

.productContent {
  margin-top: 100px;
  @media only screen and (max-width: 576px) {
    margin-top: 50px;
  }
}

/* =============================== */
/* =============================== */
/* =============================== */

.content {
  &__col {
    &--gallery {
      position: relative;
    }
  }
}

/* =============================== */
/* =============================== */
/* =============================== */

.productContent {
  h1,
  h2 {
    text-transform: uppercase;
    font-size: 1.4rem;
  }
  .swiper-container {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  .swiper-slide {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $white;
  }
  .gallery-top {
    height: 490px;
    width: 100%;
    border: 1px solid $light;
  }
  .gallery-thumbs {
    height: 150px;
    box-sizing: border-box;
    padding: 10px 0;
    @media only screen and (max-width: 576px) {
      height: 90px;
    }
  }
  .gallery-thumbs .swiper-slide {
    height: 100%;
    // opacity: 0.4;
    border: 1px solid $light;
    background-size: contain;

    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($secondary, 0.5);
      transition: background-color 200ms linear;
      cursor: pointer;
    }
    &:hover:before,
    &:focus:before {
      background-color: rgba($gray-600, 0.5);
      transition: background-color 200ms linear;
    }
  }
  .gallery-thumbs .swiper-slide-thumb-active {
    &:before {
      background-color: rgba($secondary, 0);
      transition: background-color 200ms linear;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    color: $black;
  }
}

.zoom {
  position: relative;
  z-index: 3;
  padding: 16px;
  margin-top: -45.2px;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 0.8em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  text-align: right;
  width: 100%;
  pointer-events: none;
  i{margin-right: 0.6em;}
  &__pinch {
    display: none;
  }

  @media (hover: none) {
    &__click {
      display: none;
    }
    &__pinch {
      display: block;
    }
  }
}

/* 
==========================================
Recommendation
==========================================
*/

.recommendation {
  .container {
    border-top: 1px solid $black;
  }

  .swiper-pagination-bullet-active-main {
    background-color: $black;
  }
}

/* ============================================================ */
/* ============================================================ */

.logo-slider {
  .swiper-slide {
    height: 200px;
    display: flex;
    align-items: center;
  }

  .swiper-wrapper {
    padding: 2em 0;
  }
  .swiper-pagination {
    bottom: 0;
  }

  .swiper-pagination-bullet {
    background-color: $black;
  }
}

body:not(.home) {
  background-color: $light;
}

.contact {
  margin-top: 100px;
  @media only screen and (max-width: 576px) {
  margin-top: 50px;
  }

  &__col {
    &--form {
        label{
            margin-bottom: 0;
            text-transform: uppercase;
            letter-spacing: 2px;
        }

        .form-control{
            border: none;
        }

      .btn-submit {
        color: $black;
        border: 2px solid $black;
        outline: 0 solid $white;
        outline-offset: 0px;

        &:hover {
          background-color: $black;
          color: $white;
          outline: 2px solid $white;
          outline-offset: -5px;
        }
      }
    }
  }

  a{
    color: $black;
    font-weight: normal;
    position: relative;
    &::after{
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        padding: 0.5px;
        background-color: $black;
        opacity: 0;
        transform: translateY(3px);
        transform-origin: top;
        transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
    }
    &:hover{
        color: $black;
        &::after{
            opacity: 1;
            transform: translateY(0);
            transform-origin: bottom;
            transition: transform 300ms ease-in-out, opacity 300ms ease-in-out;
        }
    }
  }
}

/* ================================ */

.map{
    &__iframe{
        width: 100%;
        min-height: 400px;
        height: 50vh;
    }
}

/* ==== */
.signUpLabel{
  letter-spacing: normal !important;
  text-transform: none !important;
  a{font-weight: 600;}
}
// Hero
.hero {
  background-color: $dark;
  position: relative;

  &__sliderContainer {
    width: 100%;
    height: 100%;
  }

  &__sliderItem {
    overflow: hidden;
    height: 100vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    color: $white;
  }

  &__sliderContent {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($black, 0.3);

    &Inner {
      margin-top: 150px;
      text-align: center;
    }
  }

  &__sliderPagination {
    bottom: 35px !important;
    .swiper-pagination-bullet {
      border-radius: 0;
      width: 40px;
      height: 3px;
      margin-right: 0.5em;
      background-color: $white;
      overflow: hidden;
      opacity: 0.5;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 5000ms ease-in-out;
      }

      &-active {
        background-color: $white;
        opacity: 1;
        &::before {
          background-color: darken($white, 30%);
          // border: 1px solid $white;
          transform: scaleX(1);
          transform-origin: left;
          transition: transform 5000ms ease-in-out;
        }
      }
    }
  }

  /* LOGO */
  &__logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;

    width: 320px;
    @media only screen and (max-width: 320px) {
      width: 230px;
    }
  }

  &__link {
    position: relative;
    color: $white;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 36px;
    text-align: center;
    letter-spacing: 8px;
    cursor: pointer;
    @media only screen and (max-width: 320px) {
      font-size: 28px;
      letter-spacing: 5px;
    }

    &:hover {
      color: $black;
    }

    &:after {
      content: "";

      display: block;
      height: 100%;
      width: 100%;

      position: absolute;
      z-index: -1;
      left: 50%;
      bottom: 0;

      transform: translateX(-50%) skew(-89deg) scaleX(1.5);

      opacity: 0;
      background: $white;

      transition: all 0.3s cubic-bezier(0.42, 0.08, 0, 1.8);
    }

    &:hover:after {
      width: 140%;

      transform: translateX(-50%) skew(-20deg) scaleX(1);

      opacity: 1;
    }
  }
}

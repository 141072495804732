.category {
  background-color: $light;

  &__container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__article {
    min-height: 400px;
    position: relative;
    overflow: hidden;
    margin-bottom: 2em;
    width: calc(33.333% - 1em);
    &:nth-child(1),
    &:nth-child(2) {
      width: calc(50% - 1em);
    }

    @media only screen and (max-width: 768px) {
      width: calc(50% - 1em);
      &:nth-child(1) {
        width: 100%;
      }
    }

    @media only screen and (max-width: 576px) {
      width: 100%;
      &:nth-child(2) {
        width: 100%;
      }
    }

    &-img {
      @include position;

      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      transform: scale(1);
      filter: blur(0);
      transition: transform 250ms ease-in-out, filter 250ms ease-in-out;
    }

    &-content {
      @include position;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba($black, 0.4);
      outline: 0 solid $white;
      outline-offset: 0;
      transition: outline 250ms ease-in-out, outline-offset 250ms ease-in-out,
        h3 250ms ease-in-out;
    }

    &-title {
      font-size: 32px;
      line-height: 38px;
      text-align: center;
      letter-spacing: 5px;
      text-transform: uppercase;
      color: $white;
      //   transform: translateY(50%);
      //   transition: transform 250ms ease-in-out;
      //   transition-delay: 120ms;
      @media only screen and (max-width: 320px) {
      font-size: 26px;
      }
    }

    &-link {
      opacity: 0;
      transform: translateY(15%);
      @media (hover: none) {
        opacity: 1;
        transform: none;
      }
    }

    &:hover {
      .category__article {
        &-img {
          transform: scale(1.2);
          filter: blur(5px);
          transition: transform 250ms ease-in-out, filter 250ms ease-in-out;
        }

        &-content {
          outline: 2px solid $white;
          outline-offset: -20px;
          transition: outline 250ms ease-in-out,
            outline-offset 250ms ease-in-out;
        }

        &-title {
          //   transform: translateY(0);
          //   transition: transform 250ms ease-in-out;
        }

        &-link {
          opacity: 1;
          transform: translateY(0);
          //   transition-delay: 200ms;
        }
      }
    }
  }
}

/* ========================== */
/* ========================== */
.texImg {
  &__row {
    justify-content: space-between;
  }
  &__col {
    &--text {
      h2 {
        font-size: calc(2.3vw + 12px);
        margin-bottom: 0.5em;
      }
    }
    &--img {
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;
    }
  }
}

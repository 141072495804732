// Colors
$dark: #101010;
$light: #F2F2F2;


/* ===================== */
@mixin position {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  /* ===================== */
